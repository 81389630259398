import React, { useState, useEffect } from 'react';
import UploadModal from './UploadModal';
import api from '../API/backend';
import axios from 'axios';
import { Row } from 'react-bootstrap';
import { useUserAuth } from "../components/UserAuthContext";
import styles from '../styles/OcrWidget.module.css';
import { Link } from 'react-router-dom';
import { CiLock } from "react-icons/ci";
import { MdOutlineFileUpload } from "react-icons/md";
import LoadingIndicator from './LoadingIndicator';


export default function OcrWidget({ setInputText, showOcrModal, setShowOcrModal, language, loading, setLoading }) {
    const { user, userData } = useUserAuth();
    const [photo, setPhoto] = useState(null);
    const [preview, setPreview] = useState(null);
    const [fileInfo, setFileInfo] = useState({ filename: '', filetype: '' });
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        if (userData["cameraCount"] > 2) {
            setIsAuthorized(true);
        }
    }, [userData]);

    const updateCameraCount = async () => {
        try {
            const response = await api.post(`${process.env.REACT_APP_BACKEND_PATH}/ocr/update-limit`, {
                uid: user.uid
            });
            if (response.status !== 200) {
                throw new Error('Error updating camera count');
            } else {
                userData["cameraCount"] = response.data["cameraCount"];
            }
        } catch (error) {
            console.error('Error updating camera count:', error);
        }
    }

    const handleCloseModal = () => {
        setShowOcrModal(false);
    };

    const generateTimestampFilename = (extension) => {
        const timestamp = Date.now();
        return `${user.uid}_${timestamp}.${extension}`;
    };

    const handleUploadPhotoTaken = async (file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        reader.readAsDataURL(file);

        setFileInfo({
            filename: generateTimestampFilename(file.name.split('.').pop()),
            filetype: file.type
        })
        handleCloseModal();
    };

    useEffect(() => {
        if (fileInfo.filename && fileInfo.filetype) {
            handleOcrScan();
            setLoading(true);
        }
    }, [fileInfo]);

    const handleOcrScan = async () => {
        try {
            // AWS S3 upload get link
            const linkResponse = await api.get(`${process.env.REACT_APP_BACKEND_PATH}/ocr/upload`, {
                params: {
                    filename: fileInfo.filename,
                    filetype: fileInfo.filetype
                }
            });
            if (linkResponse.status !== 200) {
                throw new Error('Error getting upload link');
            }

            // AWS upload file to S3
            const uploadResponse = await axios.put(linkResponse.data.uploadLink, photo, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (uploadResponse.status !== 200) {
                throw new Error('Error uploading photo to S3');
            }

            // OCR generate text
            const ocrResponse = await api.post(`${process.env.REACT_APP_BACKEND_PATH}/ocr/generate`, {
                params: {
                    filename: fileInfo.filename,
                    filetype: fileInfo.filetype,
                    language: language
                }
            });
            if (ocrResponse.status !== 200) {
                setLoading(false);
                setInputText("Non è stato possibile leggere il testo dalla foto");
            } else {
                setLoading(false);
                setInputText(ocrResponse.data);
                localStorage.setItem('inputText', ocrResponse.data);
                updateCameraCount();
                setIsAuthorized(false);
            }

        } catch (error) {
            console.error('Error scanning photo:', error);
        }
    };

    return (
        <div className={styles.ocrWrapper}>
            <Row className="p-4 radius-teseo">
                {userData.type === 'normal' && isAuthorized && <span className='regular fontL white text-center opacity-80 my-1'>Prova la funzione fotocamera!</span>}
                {(userData.type === 'premium' || userData.type === 'lifetime') && <span className='regular fontL white text-center opacity-80 my-1'>Scatta una foto e traduci!</span>}
                <div className='d-flex justify-content-center align-items-center flex-column'>
                    {userData.type === 'premium' || userData.type === 'lifetime' || isAuthorized ?
                        <>
                            <UploadModal isOpen={showOcrModal} onRequestClose={() => setShowOcrModal(false)} onPhotoTaken={handleUploadPhotoTaken} setPhoto={setPhoto} photo={photo} />

                            {preview ? (
                                <div className={styles.imagePreviewContainer}>
                                    {loading && <span className='d-flex justify-content-center'><LoadingIndicator loadingOCR={loading} /></span>}
                                    <div className={`${styles.imageWrapper} ${loading ? styles.loadingEffect : ''}`} onClick={() => setShowOcrModal(true)}>
                                        <img src={preview} alt="Selected" className={styles.imagePreview} />
                                    </div>
                                </div>
                            ) : (
                                <button onClick={() => setShowOcrModal(true)} className={`${styles.caricaButton} radius-teseo min-h-48 w-full`}>< MdOutlineFileUpload fontSize={30}></MdOutlineFileUpload></button>
                            )}
                        </>
                        :
                        <>
                            <span className='regular fontM white text-center opacity-80 my-1'>Passa a Teseo+ per usare scatta e traduci!</span>
                            <Link to={"/pay"} className={`${styles.caricaButton} min-h-48 radius-teseo w-full`}><CiLock fontSize={30}></CiLock></Link>
                        </>
                    }
                </div>
            </Row>
        </div>
    );
}
