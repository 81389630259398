import React, { useState, useEffect, useRef } from 'react';
import { Col } from "react-bootstrap";
import styles from "../../styles/Resultbox.module.css";
import { IoMdSearch } from "react-icons/io";
import WrapperWord from "./WrapperWord";
import { motion, AnimatePresence } from "framer-motion";

export default function Analysis({ analysis, lemma, loading, progress, userData }) {
    const scrollRef = useRef(null); // Create a reference for the scrollable container

    const [searchQuery, setSearchQuery] = useState('');
    const [posFilter, setPosFilter] = useState('');
    const [combinedAnalysis, setCombinedAnalysis] = useState(analysis);
    const [availablePOS, setAvailablePOS] = useState(new Set()); // Initialize as a Set
    const type = userData.type;

    useEffect(() => {
        const updatedAnalysisObject = { ...analysis };

        // Populate the available POS set, handling the "pronome" and "pronome relativo" edge case
        const posSet = new Set();
        Object.values(updatedAnalysisObject).forEach((features) => {
            if (features.pos) {
                const pos = features.pos.split(" ")[0]; // Take only the first word of the POS
                posSet.add(pos);
            }
        });
        setAvailablePOS(posSet);

        if (lemma?.data) {
            // Check user type
            if (userData["type"] === "normal") {
                // Attach only the first five lemmas for "normal" users
                lemma.data.slice(0, 5).forEach(({ lemma, text }) => {
                    if (updatedAnalysisObject[text]) {
                        updatedAnalysisObject[text] = {
                            ...updatedAnalysisObject[text],
                            lemma,
                        };
                    }
                });
            } else {
                // Attach all lemmas for premium or lifetime users
                lemma.data.forEach(({ lemma, text }) => {
                    if (updatedAnalysisObject[text]) {
                        updatedAnalysisObject[text] = {
                            ...updatedAnalysisObject[text],
                            lemma,
                        };
                    }
                });
            }
        }

        setCombinedAnalysis(updatedAnalysisObject);
    }, [analysis, lemma, availablePOS, userData]);

    const filteredWords = Object.entries(combinedAnalysis).filter(([word, features]) => {
        const matchesSearch = searchQuery === '' || word.toLowerCase().startsWith(searchQuery.toLowerCase());
        const matchesPos = posFilter === '' || features.pos?.startsWith(posFilter);
        return matchesSearch && matchesPos;
    });

    const handleFilterClick = (filter) => {
        setPosFilter(filter);

        // Reset scroll position to 0
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    };

    return (
        <Col lg={12} md={12} sm={12} xs={12} className={`justify-content-center mx-3 my-3 fontM position-relative`}>
            {loading && (
                <progress className="d-flex align-items-center mb-2" value={progress} max={100} />
            )}

            <div className={`radius-teseo text-white d-flex flex-column`}>
                <div className='bg-[#161616] d-flex p-2 radius-teseo align-items-center mb-2'>
                <span className='fontL ml-5'><IoMdSearch /></span>
                <input
                    type="text"
                    placeholder="Cerca una parola"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className={`${styles.inputBox} text-white mr-1  `}
                />
                </div>
                <motion.div layout className={` ${styles.horizontalScroll} d-flex overflow-x-auto whitespace-nowrap mb-3`}>
                    <AnimatePresence>
                    <button
                    className={`${styles.posButton} radius-teseo p-2 px-3 transition-all ${posFilter === '' ? 'bg-white text-black' : ''}`}
                    onClick={() => handleFilterClick('')}
                    >
                    All
                        </button>
                            {Array.from(availablePOS).map((pos) => (
                            <button
                                key={pos}
                                className={`${styles.posButton} radius-teseo p-2 px-3 transition-all ${posFilter === pos ? 'bg-white black' : ''}`}
                                onClick={() => handleFilterClick(pos)}
                            >
                                {pos}
                            </button>
                        ))}
                    </AnimatePresence>
                </motion.div>
     
            </div>

            <div ref={scrollRef} className={`${styles.scrollBox}`}>
                {
                    filteredWords.length > 0 ? (
                        filteredWords.map(([word, features]) => (
                            <WrapperWord word={word} features={features} type={type} key={word} />
                        ))
                    ) : (
                        <p className='fontS px-2 opacity-90'>Nessuna parola trovata.</p>
                    )
                }
            </div>
        </Col>
    );
}