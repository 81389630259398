// General imports
import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Container } from "react-bootstrap";
import { motion, useInView } from 'framer-motion';
// Component imports
import Signup from './signup';
import TextAreaUser from '../components/TextareaUser';
import TButton from '../components/TButton';
import { useTextOperations } from '../hooks/useTextOperations';
import { useUserAuth } from '../components/UserAuthContext';
import { useProcessVersion } from '../hooks/useProcessVersion';
import ResultsTabs from '../components/ResultsTabs';
import NavigationSwitch from '../components/NavigationSwitch';
import OcrWidget from '../components/OcrWidget';
import CommentSlider from '../components/CommentSlider';
import TeseoPlusBanner from '../components/TeseoPlusBanner';
import TeseoPlusBannerSticky from '../components/TeseoPlusBannerSticky';
import Teseoplus from '../components/Teseoplus';
import PopupTerms from '../components/PopupTerms';
import OcrWidgetNotRegistered from '../components/OcrWidgetNotRegistered';
import LoadingIndicator from '../components/LoadingIndicator';
import styles from '../styles/TeseoPlusBanner.module.css'
// Utils imports
import { trackEvent } from '../utils/generalUtils';
import { TbBeta } from "react-icons/tb";


function Greco() {
  const { user,
    userData,
    updateLimitInContext,
    dataReady
  } = useUserAuth();

  const [inputText, setInputText] = useState(() => {
    const savedInputText = localStorage.getItem('inputTextGreek');
    return savedInputText ? savedInputText : '';
  });
  const [showOcrModal, setShowOcrModal] = useState(false);
  const previousTextRef = useRef('');
  const [loadingOCR, setLoadingOCR] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);


  const {
    loading,
    loadingTranslation,
    loadingAnalysis,
    error,
    disabled,
    progress,
    greekAnalysis,
    lemmaGreek,
    greekTranslation,
    handleAnalyzeText,
    handleTranslateText,
    setDisabled,
    setLoading,
    setLoadingAnalysis,
    setLoadingTranslation,
    resetState,
    setError,
  } = useTextOperations();

  const {
    handleStartProcessVersion,
    openSignup,
    setOpenSignup,
    openPopupTerms,
    setOpenPopupTerms
  } = useProcessVersion({
    user,
    inputText,
    userData,
    handleTranslateText,
    handleAnalyzeText,
    updateLimitInContext,
    trackEvent,
    setDisabled,
    setLoading,
    setLoadingAnalysis,
    setLoadingTranslation,
    resetState,
    setError,
    previousTextRef,
    dataReady
  });

  useEffect(() => {
    document.body.classList.add('greco-page');
    return () => {
      document.body.classList.remove('greco-page');
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialLoad(false);
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (refFixed.current && refSticky.current) {
        const fixedRect = refFixed.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        
        if (fixedRect.top <= windowHeight) {
          refSticky.current.style.opacity = "0";
        } else {
          refSticky.current.style.opacity = "1";
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleInputChange = (event) => {
    const newText = event.target.value;
    setInputText(newText);
    localStorage.setItem('inputTextGreek', newText);
  };

  const handleCameraClick = () => {
    if (!user) {
      setOpenSignup(true);
    }
    else {
      setShowOcrModal(true);
    }
  }

  const refSticky = useRef(null);
  const refFixed = useRef(null);
  const isInView = useInView(refFixed, { once: false });


  return (
    <main>
      <Container className='pt-20'>

        {(openSignup || openPopupTerms) && <div className='blurred-background'></div>}
        {openSignup && <Signup closePopup={() => setOpenSignup(false)} popup={true} />}
        {openPopupTerms && <PopupTerms closePopup={() => setOpenPopupTerms(false)} />}

        <Row className='my-5 justify-content-center d-flex'>
          <span className='regular fontXL white text-center'>Traduci e analizza le tue versioni con l'intelligenza artificiale</span>
          <Col sm={3} xs={10} lg={3} className='text-center justify-content-center d-flex'>
            <NavigationSwitch disabled={disabled} />
          </Col>
          <div className='align-items-center p-2 opacity-70 d-flex justify-content-center'>
              <TbBeta fontSize={23}></TbBeta> <div>Teseo Greco è in versione Beta </div>
            </div>
        </Row>

        <Row className='gap-4 justify-content-center'>
          <Col lg={7} xs={12} className='background-teseo-component p-lg-5 p-3 radius-teseo h-fit'>
            
            <TextAreaUser
              initialize="Scrivi qui la tua versione di greco"
              lang="grc"
              defaultValue={inputText}
              setTextInput={handleInputChange}
              readOnly={false}
              startProcessVersion={handleStartProcessVersion}
              user={user}
              userData={userData}
              handleCameraClick={handleCameraClick}
              loading={loadingOCR}
            />

            <Row className='justify-content-center d-flex'>
              <Col sm={11} md={8} lg={8} xs={11} className="d-flex justify-content-center mt-4">
                <div className='desktop-only'>
                  <TButton onClick={() => handleStartProcessVersion("grc")} text='Chiedi a Teseo' disabled={disabled}></TButton>
                </div>
              </Col>
              <span className='text-center regular fontS white mt-1 mx-1 red'>{error}</span>
            </Row>

            <Row className='justify-content-center'>
            <LoadingIndicator 
                  loadingTranslation={loadingTranslation} 
                  loadingAnalysis={loadingAnalysis} 
              />
              <ResultsTabs
                translation={greekTranslation}
                analysis={greekAnalysis}
                lemma={lemmaGreek}
                userData={userData}
                loading={loading}
                progress={progress}
              />
            </Row>
          </Col>
          <Col lg={4}>
            {user ? (
              <div className='desktop-only h-50 max-h-72 background-teseo-component radius-teseo'>
                <OcrWidget setInputText={setInputText} showOcrModal={showOcrModal} setShowOcrModal={setShowOcrModal} language="grc" loading={loadingOCR} setLoading={setLoadingOCR} />
              </div>
            ) : (
              <div className='desktop-only h-50 max-h-72 background-teseo-component radius-teseo'>
                <OcrWidgetNotRegistered />
              </div>
            )
            }
          </Col>
        </Row>

        {(!user || userData["type"] === 'normal') && (
          <motion.div
            ref={refSticky}
            className={`${styles.stickyBannerMotion}`}
            initial={{ opacity: isInitialLoad ? 0 : 1 }}
            animate={{ 
              opacity: isInView ? 0 : 1,
              transition: { duration: 0.3 }
            }}
            transition={{ duration: 0.3 }}
          >
            <TeseoPlusBannerSticky />
          </motion.div>
        )}
      </Container>

      <Row className="mt-5 mb-5">
        <CommentSlider lang={"grc"} />
      </Row>

      <Container>
        {user ?
          userData.type === 'normal' ?
            <Row className="mt-5 mb-5 mx-2">
              <motion.div
                ref={refFixed}
                initial={{
                  opacity: 0
                }}
                whileInView={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                    delay: 0.1
                  }
                }}
                viewport={{ once: false, margin: "-100px" }}>
                <TeseoPlusBanner />
              </motion.div>
            </Row>
            :
            <Teseoplus openPopup={setOpenSignup} />
          :
          <Row className="mt-5 mb-5 mx-2">
            <motion.div
              ref={refFixed}
              initial={{
                opacity: 0
              }}
              whileInView={{
                opacity: 1,
                transition: {
                  duration: 1
                }
              }}>
              <TeseoPlusBanner />
            </motion.div>
          </Row>
        }
      </Container>
    </main>
  );
}

export default Greco;