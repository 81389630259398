import { Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from "react-router";

import styles from "../styles/TeseoPlusBanner.module.css";
import logo from '../images/logo/logo_white.svg';
import { FaRegCircleCheck } from "react-icons/fa6";

const TeseoPlusBanner = () => {

    const navigate = useNavigate();

    return(
        <div className={`${styles.banner} radius-teseo background-teseo-gradient`}>
            <Row className="d-flex justify-content-start flex-lg-row flex-column">

                <Col lg={6} className="d-flex flex-column"> 
                    <p className="white fontXXL bold" >Teseo+</p>
                    <p className="white fontL regular">L’ordinario è per tutti, <br></br> il mito per pochi.</p>   
                </Col>

                <Col lg={4} className={`${styles.customButton} d-flex align-items-start justify-content-lg-start justify-content-center my-3 my-lg-1`}>
                    <Button onClick={() => navigate("/pay")} className={`${styles.unlock_button} p-3 fontM`} variant="light">
                        SBLOCCA TESEO+
                    </Button>
                </Col>

                <Col lg={6} className="d-flex flex-column align-items-lg-center align-items-sm-start regular my-2">

                    <div className="d-flex flex-column align-items-start">
                        <p className={`${styles.customSpacing} white fontL bold`}>Cosa include </p>
                        <ul className="d-flex flex-column white FontM  list-unstyled">
                            <li className="my-1 d-flex flex-row align-items-center">
                                <FaRegCircleCheck className="white mx-2" fontSize={18} />
                                <span className={styles.customSpacing}>Fino a 25 analisi di latino e greco ogni mese </span>
                            </li>
                            <li className="my-1 d-flex flex-row align-items-center">
                                <FaRegCircleCheck className="white mx-2" fontSize={18} />
                                <span className={styles.customSpacing}>Scatta & Traduci: Carica una foto della tua versione per copiarne il testo su Teseo </span>
                            </li>
                            <li className="my-1 d-flex flex-row align-items-center">
                                <FaRegCircleCheck className="white mx-2" fontSize={18} />
                                <span className={styles.customSpacing}>Funzione dizionario: Scopri come cercare qualsiasi parola della versione </span>
                            </li>
                            <li className="my-1 d-flex flex-row align-items-center">
                                <FaRegCircleCheck className="white mx-2" fontSize={18} />
                                <span className='px-2 py-1 bg-white black bold fontS radius-teseo text-center lh-1'>Coming soon</span><span className='regular fontM mx-1 '>Lezioni di grammatica</span>
                            </li>
                        </ul>
                    </div>
                </Col>
               
            </Row> <img src={logo} alt="Logo" className={styles.logo_image} />
        </div>
    );
}

export default TeseoPlusBanner;
