import React, { useState, useEffect } from 'react';
import TranslationBox from './TranslationBox';
import Analysis from './analysis/Analysis';
import styles from '../styles/ResultsTabs.module.css';
import { FaPercent } from "react-icons/fa";

const ResultsTabs = ({ translation, analysis, lemma, userData, loading, progress }) => {
  const [activeTab, setActiveTab] = useState('translation');

  return (
    <div className="py-3">
      <div className='d-flex mb-3'>
        <button
          onClick={() => setActiveTab('translation')}
          className={`${styles.tab} ${
            activeTab === 'translation' ? styles.activeTab : styles.inactiveTab
          }`}
        >
          <span className='inter-medium'>TRADUZIONE</span>
        </button>
        <button
          onClick={() => setActiveTab('analysis')}
          className={`${styles.tab} ${
            activeTab === 'analysis' ? styles.activeTab : styles.inactiveTab
          }`}
        >
          <div className='d-flex'>
          <span className='inter-medium'>ANALISI</span>
          <span 
              className={`inter-regular fontS ml-3 d-flex align-items-center opacity-70 transition-opacity duration-500 ease-in-out ${
                progress < 100 ? 'opacity-100' : 'opacity-0'
              }`}
            >
            {(progress < 100 && progress > 0) && (
              <>
                {Math.ceil(progress)}<FaPercent fontSize={8}></FaPercent>
              </>
            )}          
          </span>
          </div>
        </button>
      </div>

      <div className={styles.content}>
        {activeTab === 'translation' && (
          <TranslationBox object={translation} />
        )}
        
        {activeTab === 'analysis' && (
          <Analysis 
            analysis={analysis}
            lemma={lemma}
            userData={userData}
            loading={loading}
            progress={progress}
          />
        )}
      </div>
    </div>
  );
};

export default ResultsTabs;