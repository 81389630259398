import { useState, useCallback, useEffect } from 'react';
import divideText from '../utils/divideText';
import api from '../API/backend';
import { useUserAuth } from "../components/UserAuthContext";

const API_URL = process.env.REACT_APP_BACKEND_PATH;

// TODO : BETTER HANDLING ERRORI 

export const useAnalysis = (uiState) => {
  const [analysis, setAnalysis] = useState(() => {
    const savedAnalysis = localStorage.getItem('displayedAnalysis');
    return savedAnalysis ? JSON.parse(savedAnalysis) : {};
  });

  const [greekAnalysis, setGreekAnalysis] = useState(() => {
    const savedAnalysisGreek = localStorage.getItem('displayedAnalysisGreek');
    return savedAnalysisGreek ? JSON.parse(savedAnalysisGreek) : {};
  });

  const [lemma, setLemmas] = useState(() => {
    const savedAnalysisLemma = localStorage.getItem('displayedAnalysisLemma');
    return savedAnalysisLemma ? JSON.parse(savedAnalysisLemma) : {};
  });

  const [lemmaGreek, setLemmasGreek] = useState(() => {
    const savedAnalysisLemmaGreek = localStorage.getItem('displayedAnalysisLemmaGreek');
    return savedAnalysisLemmaGreek ? JSON.parse(savedAnalysisLemmaGreek) : {};
  });

  const { userData } = useUserAuth();
  const { setError, setProgress } = uiState;


  const handleUpdateAnalysis = useCallback((data, currentIndex, totalParts) => {
    setAnalysis(prev => ({ ...prev, ...data }));
    setProgress(((currentIndex + 1) / totalParts) * 100); 
  }, []);

  const handleUpdateGreekAnalysis = useCallback((data, currentIndex, totalParts) => {
    setGreekAnalysis(prev => ({ ...prev, ...data }));
    setProgress(((currentIndex + 1) / totalParts) * 100);
  }, []);


  const handleUpdateError = useCallback((errorMessage) => {
    setError(errorMessage);
  }, []);


  useEffect(() => {
    localStorage.setItem('displayedAnalysisGreek', JSON.stringify(greekAnalysis));
    //console.log("analisi greco cambiata", greekAnalysis);
  }, [greekAnalysis]);
  useEffect(() => {
    localStorage.setItem('displayedAnalysis', JSON.stringify(analysis));
    //console.log("analisi cambiata", analysis); 
  }, [analysis]);
  useEffect(() => {
    localStorage.setItem('displayedAnalysisLemma', JSON.stringify(lemma));
    //console.log("lemma cambiata", lemma);
  }, [lemma]);
  useEffect(() => {
    localStorage.setItem('displayedAnalysisLemmaGreek', JSON.stringify(lemma));
    //console.log("lemma cambiata", lemma);
  }, [lemmaGreek]);


  const fetchLemmas = useCallback(async (inputText, lang) => {
    try {
      const response = await api.post(`${API_URL}/analyze/lemmas`, { text: inputText, lang: lang });
      setLemmas(prev => ({
        ...prev,
        data: [...(prev.data || []), ...(response.data.data || [])]
      }));
    } catch (error) {
      handleAnalysisError(error, handleUpdateError);
    }
  }, []);


  const analyzeText = useCallback(async (inputText, lang) => {

    setLemmas({ data: [] });

    const cleanedText = inputText.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\d]/g, '');
    const subtexts = divideText(cleanedText);
    let allSuccessful = true;
  
    for (let i = 0; i < subtexts.length; i++) {
      try {

        // Create an array of promises to execute concurrently
        const promises = [
          api.post(`${API_URL}/analyze/dynamic`, { text: subtexts[i], lang: lang })
        ];

        if(i==0 && userData["type"] === "normal") {
          promises.push(fetchLemmas(subtexts[i].split(' ').slice(0, 5).join(' '), lang));
        }
  
        // Add fetchLemmas call to promises based on user type
        else if (userData["type"] === "premium" || userData["type"] === "lifetime") {
          promises.push(fetchLemmas(subtexts[i], lang));
        }
  
        // Execute both API calls concurrently
        const [response] = await Promise.all(promises);
  
        // Update the analysis with the response data
        handleUpdateAnalysis(response.data, i, subtexts.length);
  
      } catch (error) {
        handleAnalysisError(error, handleUpdateError);
        allSuccessful = false;
      }
    }
  
    return allSuccessful;
  }, [handleUpdateAnalysis, handleUpdateError, fetchLemmas, userData]);
  



  const analyzeTextGreek = useCallback(async (inputText, lang) => {
    const cleanedText = inputText.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\d]/g, '');
    const subtexts = divideText(cleanedText);
    let allSuccessful = true;
  
    const collectedLemmas = {data: []}; // Temporary array to collect all lemmas in the required format
  
    for (let i = 0; i < subtexts.length; i++) {
      try {
        const response = await api.post(`${API_URL}/analyze/greek`, { text: subtexts[i], lang: lang });
  
        // Create a modified response object without the lemma property
        const modifiedResponse = {};
  
        Object.entries(response.data).forEach(([word, features]) => {
          // Collect lemmas in the required format
          if (features.lemma) {
            collectedLemmas.data.push({ lemma: features.lemma, text: word });
          }
  
          // Remove the lemma property and add the remaining properties to the modified response
          const { lemma, ...rest } = features; // Destructure to exclude lemma
          modifiedResponse[word] = rest; // Add the rest of the properties
        });
  
        // Pass the modified response to the next function
        handleUpdateGreekAnalysis(modifiedResponse, i, subtexts.length);
      } catch (error) {
        console.log(error);
        handleAnalysisError(error, handleUpdateError);
        allSuccessful = false;
      }
    }
  
  // Update the lemmas state with the collected lemmas
    setLemmasGreek(prev => ({ ...prev, ...collectedLemmas }));
    // Return success status
    return allSuccessful;
  }, [handleUpdateGreekAnalysis, handleUpdateError, setLemmasGreek]);






  const handleAnalyzeText = useCallback(async (inputText, lang) => {
    try {
      setError('');
      setProgress(0);

      let analysisResult;

      if(lang === "lat"){
        setAnalysis({});
        setLemmas({});
        analysisResult = await analyzeText(inputText.toLowerCase(), lang);
        
        /* if(userData["type"] === "lifetime" || userData["type"] === 'premium'){
          const lemmas = await fetchLemmas(inputText, lang);
        }
        if (userData["type"] === "normal") {
          const firstFiveWords = inputText.split(' ').slice(0, 5).join(' ');
          console.log(firstFiveWords);
          const lemmas = await fetchLemmas(firstFiveWords, lang);
        } */
      }

      else if(lang === "grc") {
        setGreekAnalysis({});
        setLemmasGreek({});
        analysisResult = await analyzeTextGreek(inputText.toLowerCase(), lang);
      }

      return ({
        status: analysisResult,
        outputAnalysis: lang==="lat" ? analysis : greekAnalysis
      })
    } catch (error) {
      setError(error.message);
      return false;
    } finally {
      
    }
  }, [uiState]);

  return {
    analysis,
    greekAnalysis,
    lemma,
    lemmaGreek,
    handleAnalyzeText
  };
};

const handleAnalysisError = (error, updateError) => {
  const TEXT_ERROR_MESSAGES = {
    400: 'Teseo traduce solo testi latini!',
    429: 'Teseo ha lavorato troppo per te oggi, torna domani.',
    default: 'Teseo si è perso nel labirinto e non può aiutarti ora.'
  };
  
  let errorMessage = 'Teseo non risponde';

  if (error.response) {
    const statusCode = error.response.status;
    errorMessage = TEXT_ERROR_MESSAGES[statusCode] || TEXT_ERROR_MESSAGES.default;
  } else if (error.request) {
    console.error('Request Error:', error.request);
  } else {
    console.error('Request Setup Error:', error.message);
  }

  updateError(errorMessage);
  throw new Error(errorMessage);
};