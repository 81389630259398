import React, { useMemo, useState }  from "react";
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { useUserAuth } from "../components/UserAuthContext";
import { sendEmailVerification, getAuth } from "firebase/auth";
import calculateNextUpdate from "../utils/calculateNextUpdate";
import { useNavigate } from "react-router";

import styles from '../styles/ProfileBox.module.css'


const ProfileBox = ({type}) => {

    const {user, userData, updateLimitInContext, dataReady } = useUserAuth();
    const userType = userData["type"];
    const [message, setMessage] = useState("");
    const [messageLimits, setLimitMessage] = useState("");
    const [disabledButtonLimits, setDisableLimit] = useState(false);
    const nextUpdateDate = useMemo(() => calculateNextUpdate(userData.lastUpdate), [userData.lastUpdate]);
    const navigate = useNavigate();

    function resendEmail() {
        if (user != null){
          const auth = getAuth();
          sendEmailVerification(auth.currentUser).then(() => {
            setMessage("Email inviata, controlla anche la posta indesiderata");            
          })
          setTimeout(() => {
            if(document.getElementById("email_alert") != null) {
               document.getElementById("email_alert").remove();
            }
          }, 6000);
        }
    }

    const handleUpdateLimitInContext = async() => {
        try{
            setLimitMessage("");
            setDisableLimit(true);
            if(dataReady) {
                await updateLimitInContext();
                setLimitMessage("Limiti aggiornati correttamente");
            }
            else {
                setLimitMessage("Dati non caricati correttamente")
            }
        }
        catch(e) {
            setLimitMessage("C'è qualcosa che non va");
        }
        finally {
            setDisableLimit(false);
        }
    }

    return(
        <>
            {(() => {
            switch (type) {
            case 'details':
                return <>
                    <Row className={`${styles.box} p-lg-4 m-0 pb-0 p-2 justify-content-center`}>
                        <Col className={`${styles.profile_box} col-lg-6 col-11 p-4 gap-4 d-flex flex-col justify-content-start align-items-start radius-teseo`}>
                            
                            <div>
                                <p className="bold fontL pb-1">La tua email</p>
                                <div className="justify-content-center align-items-center">
                                    <p className="fontM">{user.email}</p>
                                    {user && user?.emailVerified ? (
                                        <p className="fontS opacity-60">Verificata</p>
                                    ): 
                                    <div className="d-flex gap-2">
                                        <p className="cursor-pointer white underline fontS opacity-80" onClick={() => {navigate(0)}}>Ho verificato</p>
                                        <p className="cursor-pointer white underline fontS opacity-80 " onClick={resendEmail}>Invia nuova email di verifica</p>
                                    </div>
                                    }
                                </div>
                            </div>
                            
                            <div>
                                <p className="bold fontL pb-1">Nome utente </p>
                                <p className="fontM">{userData.nome}</p>
                            </div>

                            <div>
                                <p className="bold fontL pb-1">Scuola</p>
                                <p className="fontM">{userData.scuola}</p>
                            </div>

                            <div>
                                <p className="bold fontL pb-1">Anno di iscrizione</p>
                                <p className="fontM">{userData.anno}</p>    
                            </div>
                            
                            {/* <Button onClick={() => navigate("/pay")} className={`${styles.unlock_button} p-3 fontM`}>
                                SBLOCCA TESEO+
                            </Button> */}
                        </Col>
                    </Row>

                    {message &&
                                <Row className= {`d-flex flex-col align-content-center`}>
                                <Col className="col-11">
                                    <Alert className= {`${styles.alert} d-flex flex-row justify-content-between`} variant="success">
                                        <span >{message}</span>
                                        <button type="button" className="btn-close" onClick={() =>  setMessage("")} aria-label="Close"></button>
                                    </Alert> 
                                </Col>
                                
                                </Row>  
                    }
                </>
            case 'subscription':
                return <>
                    <Row className={`${styles.box} p-lg-4 m-0 pb-0 p-2 justify-content-center`}>
                        <Col className={`${styles.profile_box} col-lg-6 col-11 p-4 d-flex flex-col justify-content-start align-items-start radius-teseo`}>
                            <p className="bold fontXL pb-2">Teseo.app </p>
                            {userType==='lifetime' || userType==='premium' ? 
                                <p className="bold fontL">Premium</p> :
                                <p className="bold fontL">Normale</p>
                            }
                            <p className="fontM">{userData.membership}</p>
                            <ul className="d-flex flex-col gap-3 my-3">
                                <li className="d-flex flex-row align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.455 7.45474C16.6663 7.25357 16.9475 7.14236 17.2392 7.14456C17.531 7.14677 17.8104 7.26223 18.0187 7.46657C18.2269 7.67091 18.3476 7.94815 18.3553 8.2398C18.363 8.53144 18.2571 8.81468 18.06 9.02974L12.075 16.5147C11.9721 16.6256 11.8479 16.7145 11.7098 16.7763C11.5717 16.838 11.4226 16.8713 11.2714 16.8741C11.1201 16.8769 10.9699 16.8492 10.8296 16.7926C10.6894 16.736 10.5619 16.6517 10.455 16.5447L6.48599 12.5757C6.37546 12.4727 6.28681 12.3485 6.22532 12.2105C6.16383 12.0725 6.13077 11.9236 6.1281 11.7725C6.12544 11.6215 6.15323 11.4714 6.20981 11.3313C6.26639 11.1913 6.35061 11.064 6.45743 10.9572C6.56426 10.8503 6.69151 10.7661 6.8316 10.7096C6.97168 10.653 7.12172 10.6252 7.27278 10.6278C7.42383 10.6305 7.5728 10.6636 7.7108 10.7251C7.8488 10.7866 7.973 10.8752 8.07599 10.9857L11.217 14.1252L16.4265 7.48774C16.4359 7.47619 16.4459 7.46517 16.4565 7.45474H16.455Z" fill="white"/>
                                    </svg>
                                    <p>25 traduzioni al mese</p>
                                </li>
                                <li className="d-flex flex-row align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.455 7.45474C16.6663 7.25357 16.9475 7.14236 17.2392 7.14456C17.531 7.14677 17.8104 7.26223 18.0187 7.46657C18.2269 7.67091 18.3476 7.94815 18.3553 8.2398C18.363 8.53144 18.2571 8.81468 18.06 9.02974L12.075 16.5147C11.9721 16.6256 11.8479 16.7145 11.7098 16.7763C11.5717 16.838 11.4226 16.8713 11.2714 16.8741C11.1201 16.8769 10.9699 16.8492 10.8296 16.7926C10.6894 16.736 10.5619 16.6517 10.455 16.5447L6.48599 12.5757C6.37546 12.4727 6.28681 12.3485 6.22532 12.2105C6.16383 12.0725 6.13077 11.9236 6.1281 11.7725C6.12544 11.6215 6.15323 11.4714 6.20981 11.3313C6.26639 11.1913 6.35061 11.064 6.45743 10.9572C6.56426 10.8503 6.69151 10.7661 6.8316 10.7096C6.97168 10.653 7.12172 10.6252 7.27278 10.6278C7.42383 10.6305 7.5728 10.6636 7.7108 10.7251C7.8488 10.7866 7.973 10.8752 8.07599 10.9857L11.217 14.1252L16.4265 7.48774C16.4359 7.47619 16.4459 7.46517 16.4565 7.45474H16.455Z" fill="white"/>
                                    </svg>
                                    <p>25 analisi al mese</p>
                                </li>
                                <li className="d-flex flex-row align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.455 7.45474C16.6663 7.25357 16.9475 7.14236 17.2392 7.14456C17.531 7.14677 17.8104 7.26223 18.0187 7.46657C18.2269 7.67091 18.3476 7.94815 18.3553 8.2398C18.363 8.53144 18.2571 8.81468 18.06 9.02974L12.075 16.5147C11.9721 16.6256 11.8479 16.7145 11.7098 16.7763C11.5717 16.838 11.4226 16.8713 11.2714 16.8741C11.1201 16.8769 10.9699 16.8492 10.8296 16.7926C10.6894 16.736 10.5619 16.6517 10.455 16.5447L6.48599 12.5757C6.37546 12.4727 6.28681 12.3485 6.22532 12.2105C6.16383 12.0725 6.13077 11.9236 6.1281 11.7725C6.12544 11.6215 6.15323 11.4714 6.20981 11.3313C6.26639 11.1913 6.35061 11.064 6.45743 10.9572C6.56426 10.8503 6.69151 10.7661 6.8316 10.7096C6.97168 10.653 7.12172 10.6252 7.27278 10.6278C7.42383 10.6305 7.5728 10.6636 7.7108 10.7251C7.8488 10.7866 7.973 10.8752 8.07599 10.9857L11.217 14.1252L16.4265 7.48774C16.4359 7.47619 16.4459 7.46517 16.4565 7.45474H16.455Z" fill="white"/>
                                    </svg>
                                    <p>Scan della versione</p>
                                </li>
                                <li className="d-flex flex-row align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.455 7.45474C16.6663 7.25357 16.9475 7.14236 17.2392 7.14456C17.531 7.14677 17.8104 7.26223 18.0187 7.46657C18.2269 7.67091 18.3476 7.94815 18.3553 8.2398C18.363 8.53144 18.2571 8.81468 18.06 9.02974L12.075 16.5147C11.9721 16.6256 11.8479 16.7145 11.7098 16.7763C11.5717 16.838 11.4226 16.8713 11.2714 16.8741C11.1201 16.8769 10.9699 16.8492 10.8296 16.7926C10.6894 16.736 10.5619 16.6517 10.455 16.5447L6.48599 12.5757C6.37546 12.4727 6.28681 12.3485 6.22532 12.2105C6.16383 12.0725 6.13077 11.9236 6.1281 11.7725C6.12544 11.6215 6.15323 11.4714 6.20981 11.3313C6.26639 11.1913 6.35061 11.064 6.45743 10.9572C6.56426 10.8503 6.69151 10.7661 6.8316 10.7096C6.97168 10.653 7.12172 10.6252 7.27278 10.6278C7.42383 10.6305 7.5728 10.6636 7.7108 10.7251C7.8488 10.7866 7.973 10.8752 8.07599 10.9857L11.217 14.1252L16.4265 7.48774C16.4359 7.47619 16.4459 7.46517 16.4565 7.45474H16.455Z" fill="white"/>
                                    </svg>
                                    <p>Scopri come cercare ogni parola della versione</p>
                                </li>
                                <li className="d-flex flex-row align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.455 7.45474C16.6663 7.25357 16.9475 7.14236 17.2392 7.14456C17.531 7.14677 17.8104 7.26223 18.0187 7.46657C18.2269 7.67091 18.3476 7.94815 18.3553 8.2398C18.363 8.53144 18.2571 8.81468 18.06 9.02974L12.075 16.5147C11.9721 16.6256 11.8479 16.7145 11.7098 16.7763C11.5717 16.838 11.4226 16.8713 11.2714 16.8741C11.1201 16.8769 10.9699 16.8492 10.8296 16.7926C10.6894 16.736 10.5619 16.6517 10.455 16.5447L6.48599 12.5757C6.37546 12.4727 6.28681 12.3485 6.22532 12.2105C6.16383 12.0725 6.13077 11.9236 6.1281 11.7725C6.12544 11.6215 6.15323 11.4714 6.20981 11.3313C6.26639 11.1913 6.35061 11.064 6.45743 10.9572C6.56426 10.8503 6.69151 10.7661 6.8316 10.7096C6.97168 10.653 7.12172 10.6252 7.27278 10.6278C7.42383 10.6305 7.5728 10.6636 7.7108 10.7251C7.8488 10.7866 7.973 10.8752 8.07599 10.9857L11.217 14.1252L16.4265 7.48774C16.4359 7.47619 16.4459 7.46517 16.4565 7.45474H16.455Z" fill="white"/>
                                    </svg>
                                    <p>Tutte le prossime funzionalità</p>
                                </li>
                            </ul>
                            
                            <Button onClick={() => navigate("/pay")} className={`${styles.unlock_button} p-3 fontM`}>
                                SBLOCCA TESEO+
                            </Button>
                        </Col>
                    </Row>
                </>
            case 'credit':
                return <>
                    <Row className={`${styles.box} p-lg-4 m-0 pb-0 p-2 justify-content-center`}>
                        <Col className={`${styles.profile_box} col-lg-6 col-11 p-4 d-flex flex-col justify-content-start align-items-start radius-teseo`}>
                            <p className="bold fontL">Il tuo credito </p>
                            <ul className="d-flex flex-col list-disc m-3 gap-2">
                                <li className="fontM">
                                    <p>{userData["latinoCount"]} versioni di latino. </p>
                                </li>
                                <li className="fontM">
                                    <p>{userData["grecoCount"]} versioni di greco.</p>
                                </li>
                            </ul>

                            <div className="mb-3 fontM">
                                <span>Prossimo aggiornamento il</span>
                                <span className="inter-bold m-2">{nextUpdateDate}</span> 
                            </div>
                            <Button onClick={handleUpdateLimitInContext} className={`${styles.unlock_button} p-3 fontM`} disabled={disabledButtonLimits}>
                                AGGIORNA
                            </Button>
                        </Col>
                    </Row>
                    {messageLimits &&
                        <Row className= {`d-flex flex-col align-content-center`}>
                        <Col className="col-11">
                            <Alert className= {`${styles.alert} d-flex flex-row justify-content-between`} variant="success">
                                <span >{messageLimits}</span>
                                <button type="button" className="btn-close" onClick={() =>  setLimitMessage("")} aria-label="Close"></button>
                            </Alert> 
                        </Col>
                        </Row>  
                    }
                </>
            }
            })()}
        </>
    );
}

export default ProfileBox;