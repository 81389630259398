import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const LoadingIndicator = ({ loadingTranslation, loadingAnalysis, loadingOCR }) => {
  return (
    <div className="d-flex overflow-hidden position-relative inter-regular gap-x-3 mx-2">
      <AnimatePresence>
        {loadingTranslation && (
          <motion.div
            key="translation"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="flex items-center justify-center"
          >
            <div className="relative">
              <motion.span
                animate={{
                  backgroundPosition: ['0% 0%', '100% 50%'],
                  transition: {
                    repeat: Infinity,
                    duration: 3,
                    ease: 'linear',
                  },
                }}
                className="text-transparent bg-clip-text bg-gradient-to-r from-gray-500 via-gray-700 to-gray-500 bg-position-0"
                style={{
                    backgroundSize: '500% auto', // Allows smooth scrolling of gradient
                    display: 'inline-block', // Prevents collapsing of the text container
                  }}
              >
                Sto traducendo...
              </motion.span>
            </div>
          </motion.div>
        )}

        {loadingAnalysis && (
            <motion.div
              key="analysis"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="flex items-center justify-center"
            >
              <div className="relative">
                <motion.span
                  animate={{
                    backgroundPosition: ['0% 00%', '100% 50%'],
                    transition: {
                      repeat: Infinity,
                      duration: 2,
                      ease: 'linear',
                    },
                  }}
                  className="text-transparent bg-clip-text bg-gradient-to-r from-gray-500 via-gray-700 to-gray-500 bg-position-0"
                  style={{
                      backgroundSize: '500% auto', // Allows smooth scrolling of gradient
                      display: 'inline-block', // Prevents collapsing of the text container
                    }}
                >
                  Sto analizzando...
                </motion.span>
              </div>
            </motion.div>
        )}

        {loadingOCR && (
          <motion.div
            key="translation"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="flex items-center justify-center"
          >
            <div className="relative">
              <motion.span
                animate={{
                  backgroundPosition: ['0% 0%', '100% 50%'],
                  transition: {
                    repeat: Infinity,
                    duration: 3,
                    ease: 'linear',
                  },
                }}
                className="text-transparent bg-clip-text bg-gradient-to-r from-gray-300 via-gray-500 to-gray-300 bg-position-0"
                style={{
                    backgroundSize: '500% auto', // Allows smooth scrolling of gradient
                    display: 'inline-block', // Prevents collapsing of the text container
                  }}
              >
                Sto leggendo...
              </motion.span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Set default values for props
LoadingIndicator.defaultProps = {
  loadingTranslation: false,
  loadingAnalysis: false,
  loadingOCR: false,
};

export default LoadingIndicator;
