import { MdMenuBook } from "react-icons/md";
import { Link } from "react-router-dom";

export default function WrapperLemma(props) {

    const {lemma, type} = props;

    return (
        <div className="d-flex align-items-center">
          <MdMenuBook />
          {lemma ? (
            <span className="premium-tag mx-1">
              Cerca nel dizionario come: {lemma.replace(/[0-9.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')}
            </span>
          ) : (
            type === "normal" && (
              <span className="fontS mx-1 underline">
                <Link to="/pay">Come la cerco nel dizionario?</Link>
              </span>
            )
          )}
        </div>
      );
}