import React, { useState, useEffect } from 'react';

const TermsAndConditions = () => {
    const htmlContent = `
            <!DOCTYPE html>
        <html>
            <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <meta charset="utf-8" />
                <title>
                </title>
                <style>
                    body { font-family:Aptos; font-size:12pt }
                    h1, h2, h3, h4, h5, h6, p { margin:0pt }
                    li { margin-top:0pt; margin-bottom:0pt }
                    h1 { margin-top:18pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; font-family:'Aptos Display'; font-size:20pt; font-weight:normal; color:#0f4761 }
                    h2 { margin-top:8pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; font-family:'Aptos Display'; font-size:16pt; font-weight:normal; color:#0f4761 }
                    h3 { margin-top:8pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; font-family:Aptos; font-size:14pt; font-weight:normal; color:#0f4761 }
                    h4 { margin-top:4pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; font-family:Aptos; font-size:12pt; font-weight:normal; font-style:italic; color:#0f4761 }
                    h5 { margin-top:4pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; font-family:Aptos; font-size:12pt; font-weight:normal; color:#0f4761 }
                    h6 { margin-top:2pt; page-break-inside:avoid; page-break-after:avoid; font-family:Aptos; font-size:12pt; font-weight:normal; font-style:italic; color:#595959 }
                    .Heading7 { margin-top:2pt; page-break-inside:avoid; page-break-after:avoid; font-family:Aptos; font-size:12pt; font-weight:normal; font-style:normal; color:#595959 }
                    .Heading8 { page-break-inside:avoid; page-break-after:avoid; font-family:Aptos; font-size:12pt; font-weight:normal; font-style:italic; color:#272727 }
                    .Heading9 { page-break-inside:avoid; page-break-after:avoid; font-family:Aptos; font-size:12pt; font-weight:normal; font-style:normal; color:#272727 }
                    .IntenseQuote { margin:18pt 43.2pt; text-align:center; border-top:0.75pt solid #0f4761; border-bottom:0.75pt solid #0f4761; padding-top:10pt; padding-bottom:10pt; font-size:12pt; font-style:italic; color:#0f4761 }
                    .ListParagraph { margin-left:36pt; font-size:12pt }
                    .NormalWeb { font-family:'Times New Roman'; font-size:12pt }
                    .Quote { margin-top:8pt; margin-bottom:8pt; text-align:center; font-size:12pt; font-style:italic; color:#404040 }
                    .Subtitle { margin-bottom:8pt; font-size:14pt; letter-spacing:0.75pt; color:#595959 }
                    .Title { margin-bottom:4pt; font-family:'Aptos Display'; font-size:28pt; letter-spacing:-0.5pt }
                    span.Heading1Char { font-family:'Aptos Display'; font-size:20pt; color:#0f4761 }
                    span.Heading2Char { font-family:'Aptos Display'; font-size:16pt; color:#0f4761 }
                    span.Heading3Char { font-size:14pt; color:#0f4761 }
                    span.Heading4Char { font-style:italic; color:#0f4761 }
                    span.Heading5Char { color:#0f4761 }
                    span.Heading6Char { font-style:italic; color:#595959 }
                    span.Heading7Char { color:#595959 }
                    span.Heading8Char { font-style:italic; color:#272727 }
                    span.Heading9Char { color:#272727 }
                    span.Hyperlink { text-decoration:underline; color:#467886 }
                    span.IntenseEmphasis { font-style:italic; color:#0f4761 }
                    span.IntenseQuoteChar { font-style:italic; color:#0f4761 }
                    span.IntenseReference { font-weight:bold; font-variant:small-caps; letter-spacing:0.25pt; color:#0f4761 }
                    span.QuoteChar { font-style:italic; color:#404040 }
                    span.SubtitleChar { font-size:14pt; letter-spacing:0.75pt; color:#595959 }
                    span.TitleChar { font-family:'Aptos Display'; font-size:28pt; letter-spacing:-0.5pt }
                    span.UnresolvedMention { color:#605e5c; background-color:#e1dfdd }
        @media (max-width: 900px) { 
        img { 
        max-width: 100%;
        height: auto;
        }

        .table-container {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

        table {
            width: 100%;
            border-collapse: collapse;
        }

        td, th {
            padding: 8px;
            text-align: left;
            border: 1px solid #ddd;
        }
        }	


                </style>
            </head>
            <body>
                    <p>
                        <a id="OLE_LINK1"></a><a id="OLE_LINK2"><strong>Condizioni Generali di Vendita</strong>, in vigore dal 16/10/2024</a>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        <strong>PREMESSA</strong>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        La presente informativa è resa per il sito https://www.teseo.app
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        Dati del Venditore: MemorAIz srl, Bologna Italy
                    </p>
                    <p>
                        <strong>Indirizzo email del Titolare:</strong>&#xa0;<a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="5f373a3333301f323a32302d1e1625713c3032">[email&#160;protected]</a>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        | PIVA: 04220581203 | Via Lame 58, Bologna (Cap. 40122) 
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        Servizio venduto sul Sito: Abbonamento a Teseo.app
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        <strong>Art. 1 Ambito di applicazione</strong>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        1.1 Le Condizioni Generali di Vendita si applicano a tutte le vendite effettuate dal Venditore sul Sito.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        1.2 Qualora sia reso possibile dal Sito, l'inserimento del Suo codice fiscale in occasione di un acquisto implica che Lei sta agendo a titolo di "Consumatore".&#xa0;Si ricorda che riveste la qualità di Consumatore la persona fisica che agisce per scopi estranei all’attività imprenditoriale, commerciale, professionale o artigianale eventualmente svolta. 
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        1.3 I termini indicati sono da intendersi come giorni lavorativi, esclusi quindi sabato, domenica e le festività nazionali. Le immagini e le descrizioni presenti sul Sito sono da intendersi come meramente indicative.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        1.4 Le Condizioni Generali di Vendita possono essere modificate in ogni momento. Eventuali modifiche e/o nuove condizioni saranno in vigore dal momento della loro pubblicazione sul Sito. Lei è pertanto invitato ad accedere con regolarità al Sito e a consultare, prima di effettuare qualsiasi acquisto, la versione più aggiornata delle Condizioni Generali di Vendita.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        1.5 Le Condizioni Generali di Vendita applicabili sono quelle in vigore alla data di invio dell’ordine di acquisto.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        1.6 Le presenti Condizioni Generali di Vendita non disciplinano la vendita di prodotti e/o servizi da parte di soggetti diversi dal Venditore che siano eventualmente presenti sul Sito tramite link, banner o altri collegamenti iper-testuali.&#xa0;Prima di effettuare transazioni commerciali con tali soggetti è necessario verificare le loro condizioni di vendita.&#xa0;Il Venditore non è responsabile per la fornitura di servizi e/o per la vendita di prodotti da parte di tali soggetti.&#xa0;
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        1.7 Sui siti web consultabili tramite tali collegamenti il Venditore non effettua alcun controllo e/o monitoraggio.&#xa0;Il Venditore non è pertanto responsabile per i contenuti di tali siti né per eventuali errori e/o omissioni e/o violazioni di legge da parte degli stessi.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        1.8 Lei è tenuto a leggere attentamente le presenti Condizioni Generali di Vendita nonché tutte le altre informazioni che il Venditore fornisce sul Sito, anche durante la procedura di acquisto. Per usufruire di uno o più dei Servizi offerti dal Venditore Lei è tenuto a corrispondere un abbonamento periodico, secondo le scadenze e gli importi di volta in volta indicati sul Sito in base all’abbonamento da selezionato. L’abbonamento può essere disdetto tramite l’apposita funzione presente nella Sua area riservata, oppure seguendo le indicazioni di volta in volta fornite sul Sito o dal Venditore. In ogni caso la disdetta dell’abbonamento sarà efficace a decorrere dalla prima data di rinnovo dell'abbonamento.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        1.9 Sul Sito è possibile inoltrare ordini anche per email. Per quanto compatibili, anche per queste tipologie di ordini valgono le clausole indicate nelle presente Condizioni Generali di Vendita. Il Venditore si riserva di inviarLe per email delle diverse e separate Condizioni Generali di Vendita, efficaci solo per l'acquisto per email. Il Venditore non accetta ordini per telefono, salvo diverso accordo intercorso con il cliente.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        1.10 L'insieme di qualsiasi elemento del Sito è proprietà del Venditore o di terzi. Salvo specifico consenso scritto del Venditore, è proibito riprodurre, integralmente o parzialmente e mediante qualsivoglia procedimento, distribuire, pubblicare, trasmettere, modificare o vendere tutto o parte del contenuto del Sito.&#xa0;
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        1.11 Il Venditore non potrà in alcun caso essere ritenuto responsabile nei confronti Suoi o di terzi per alcun danno indiretto, incidentale, speciale o consequenziale. È compresa, a titolo esemplificativo, qualsiasi perdita di guadagno o altra perdita indiretta risultante dall'utilizzo del Sito o dall'incapacità di utilizzo. Il Venditore non può garantire né affermare: (i) che il Sito è esente da virus o programmi che possano danneggiare i dati; (ii) che le informazioni contenute nel Sito siano esatte, complete e aggiornate.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        1.12 Il presente documento disciplina integralmente il rapporto tra Lei e il Venditore. Sono in ogni caso fatti salvi i diritti e gli obblighi previsti dalla legge di volta in volta applicabile. Il Venditore potrà organizzare concorsi e operazioni a premio riservati agli utenti del Sito. Il regolamento di ciascun concorso o operazione a premio sarà consultabile in una apposita sezione del Sito. Ove i premi siano costituiti da buoni sconto, buoni acquisto o forme equivalenti, essi non potranno in alcun caso essere convertiti in denaro.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        <strong>Art. 2 Acquisti sul Sito</strong>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        2.1 Per effettuare acquisti sul Sito è necessario seguire il procedimento presente sul Sito stesso, inserendo i dati di volta in volta richiesti. Il contratto di vendita è concluso quando l'ordine perviene al server del Venditore.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        2.2 Lei si impegna a informare immediatamente il Venditore nel caso in cui sospetti o venga a conoscenza di un uso indebito o di una indebita divulgazione di qualsiasi informazione da Lei inserita sul Sito.&#xa0;
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        2.3 Lei garantisce che le informazioni personali fornite sono complete e veritiere e si impegna a tenere il Venditore indenne e manlevato da qualsiasi danno, obbligo risarcitorio e/o sanzione derivante da e/o in qualsiasi modo collegata alla violazione di questo impegno.&#xa0;Lei si impegna ad informare immediatamente il Venditore nel caso in cui sospetti o venga a conoscenza di un uso indebito o di una indebita divulgazione delle credenziali di accesso al Sito.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        2.4 Il Venditore si riserva il diritto di rifiutare ordini che provengano da utenti che abbiamo in precedenza violato le presenti Condizioni Generali di Vendita o qualsiasi disposizione normativa.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        2.5 Per effettuare ordini sul Sito non è necessario accettare le presenti Condizioni Generali di Vendita. Ad ogni modo, l'invio dell'ordine di acquisto costituisce accettazione del presente documento.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        2.6 Successivamente all'acquisto, Lei riceverà una email di conferma dell'ordine. L'email di conferma dell'ordine conterrà almeno le seguenti informazioni: (i) dati del Venditore; (ii) caratteristiche del Prodotto acquistato; (iii) prezzo di acquisto ed eventuali tasse; (iv) qualsiasi maggiorazione di costo; (v) diritto di recesso o sua esclusione; (vi) indirizzo di spedizione; (vii) mezzo di pagamento utilizzato.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        2.7 Per usufruire dei servizi offerti in vendita sul Sito non è richiesto il pagamento di una somma a titolo di caparra.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        <strong>Art. 3 Prezzi</strong>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        3.1 Sul Sito:
                    </p>
                    <ul style="margin:0pt; padding-left:0pt">
                        <li style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt">
                            <span style="font-family:Aptos; font-size:12pt">i prezzi comprendono l'IVA.</span>
                        </li>
                    </ul>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        3.2&#xa0;Il Venditore si riserva il diritto di modificare il prezzo dei Servizi, in ogni momento, senza preavviso, fermo restando che il prezzo a Lei addebitato sarà quello indicato sul Sito al momento dell'effettuazione dell'ordine e che non si terrà conto di eventuali variazioni (in aumento o in diminuzione) successive alla trasmissione dello stesso.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        3.3 Il contratto di acquisto è risolutivamente condizionato al mancato pagamento dell'Importo Totale Dovuto. Salvo diverso accordo scritto con Lei intercorso, l'ordine sarà conseguentemente cancellato.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        <strong>Art. 4 Modalità di pagamento</strong>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        4.1 Il presente articolo descrive le modalità di pagamento disponibili sul Sito. L'utente può leggere maggiori informazioni accedendo alla sezione dedicata ai "Pagamenti" presente sul Sito. L'utente può accedere a questa sezione direttamente dal footer del Sito.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        4.2 Sul Sito Lei può acquistare tramite carte di pagamento. L'addebito sarà effettuato solo dopo che (i) saranno stati verificati i dati della Sua carta di pagamento utilizzata per il pagamento e (ii) la società emittente della carta di pagamento da Lei utilizzata avrà rilasciato l'autorizzazione all'addebito. In applicazione della direttiva 2015/2366/ (UE) sui servizi di pagamento nel mercato interno (PSD2), si informa l’utente che potrà essere richiesto di completare il procedimento di acquisto soddisfando i criteri di autenticazione richiesti dall’istituto di pagamento incaricato di gestire l’operazione di pagamento online. I criteri di autenticazione sono riferiti alla identità dell’utente (per soddisfare questo criterio l’utente deve essere registrato al Sito in occasione dell’operazione di acquisto) e alla contestuale conoscenza del codice di autenticazione trasmesso dall’istituto di pagamento (Strong Customer Authentication). Il mancato perfezionamento del procedimento sopra descritto potrà implicare l'impossibilità di finalizzare l’acquisto sul Sito. I dati riservati della carta di pagamento (numero della carta, intestatario, data di scadenza, codice di sicurezza) sono criptati e trasmessi direttamente al gestore dei pagamenti senza transitare dai server di cui il Venditore si serve. Il Venditore, quindi, non ha mai accesso e non memorizza, neanche nel caso in cui Lei scelga di memorizzare tali dati sul Sito, i dati della Sua carta di pagamento utilizzata per il pagamento del Servizio.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        4.3 Il presente articolo indica le carte di credito e/o di debito eventualmente accettate sul Sito.
                    </p>
                    <ul style="margin:0pt; padding-left:0pt">
                        <li style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt">
                            <span style="font-family:Aptos; font-size:12pt">VISA.</span>
                        </li>
                        <li style="margin-left:27.6pt; padding-left:8.4pt; font-family:serif; font-size:10pt">
                            <span style="font-family:Aptos; font-size:12pt">MasterCard.</span>
                        </li>
                    </ul>
                    <p style="margin-left:36pt">
                        &#xa0;
                    </p>
                    <p>
                        4.4 Sul Sito è possibile pagare tramite bonifico bancario. In questo caso le coordinate per procedere al pagamento saranno disponibili direttamente sul Sito oppure comunicate per email successivamente all'inoltro dell'ordine di acquisto.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        4.5 Sul Sito non è possibile effettuare acquisti utilizzando buoni sconto, voucher o coupon. 
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        4.6 Eventuali modalità diverse rispetto a quelle sopra descritte potranno essere disciplinate nel presente articolo:
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        <strong>Art. 5 Diritto di recesso</strong>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        5.1 Si invita l'utente a visionare con particolare attenzione il presente articolo, il quale disciplina il diritto di recesso.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        5.2 Il diritto di recesso è il diritto del Consumatore di sciogliere il contratto di acquisto senza essere obbligato a fornire una motivazione. Lei può esercitare questo diritto entro 14 giorni di calendario, decorrenti dalla conclusione del contratto. Se Lei ha acquistato in qualità di Professionista il diritto di recesso non si applica, tranne diverso accordo intercorso con il Venditore.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        Eventuali eccezioni al diritto di recesso sono riportate al presente articolo 5. Se non vi sono eccezioni al diritto di recesso, il presente articolo 5 trova integrale applicazione.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        In casi di recesso, il Venditore si astiene dall’utilizzare qualsiasi contenuto, diverso dai dati personali, che è stato da Lei fornito o creato durante il contenuto del contenuto digitale o del servizio digitale, salvo quando tale contenuto: (i) è privo di utilità al di fuori del contesto del contenuto digitale o del servizio digitale fornito dal Venditore; (ii) riguarda unicamente la Sua attività durante l’utilizzo del contenuto digitale o del servizio digitale fornito dal professionista; (iii) è stato aggregato da l Venditore ad altri dati e non può essere disaggregato o può esserlo soltanto con sforzi sproporzionati; (iv) è stato generato congiuntamente da Lei e altre persone, i quali continuano a farne uso anche dopo il recesso dal contratto. Fatta eccezione per quanto indicato al comma che precede, ai punti (i), (ii) e (iii), il Venditore, su Sua richiesta, mette a disposizione qualsiasi contenuto, diverso dai dati personali, fornito o creato dal consumatore durante l’utilizzo del contenuto digitale o del servizio digitale fornito dal Venditore. Lei ha diritto di recuperare dal Venditore tali contenuti digitali gratuitamente e senza impedimenti, entro un lasso di tempo ragionevole e in un formato di uso comune e leggibile da dispositivo automatico. In caso di recesso dal contratto, il Venditore può impedire qualsiasi ulteriore utilizzo del contenuto digitale o del servizio digitale da parte Sua, in particolare rendendo inaccessibile tale contenuto o servizio digitale o disattivando il Suo account, fatto salvo quanto previsto ai punti (i), (ii) e (iii) di cui sopra.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        5.3 Il Venditore La informa che sul Sito il recesso è escluso per i servizi completamente eseguiti prima del decorso del periodo di 14 giorni, con il Suo accordo espresso di perdere il diritto di recesso. Per i Servizi parzialmente eseguiti, l'esclusione del diritto di recesso troverà applicazione con riferimento ai Servizi già eseguiti.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        <strong>Art. 6 Garanzia Legale di Conformità</strong>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        6.1 La Garanzia Legale di Conformità è riservata al Consumatore. Essa, pertanto, trova applicazione, solo agli utenti che hanno effettuato l'acquisto sul Sito per scopi estranei all'attività imprenditoriale, commerciale, artigianale o professionale eventualmente svolta.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        6.2 Il Venditore è responsabile nei confronti del Consumatore per qualsiasi difetto di conformità del Servizio che si manifesti entro due anni dall'acquisto. L'azione diretta a far valere i difetti non dolosamente occultati dal Venditore si prescrive, in ogni caso, nel termine di ventisei mesi dalla prestazione del Servizio. In caso di difetto di conformità' del Servizio, il Consumatore ha diritto al ripristino della conformità' o a ricevere una riduzione proporzionale del prezzo o alla risoluzione del contratto per grave inadempimento.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        6.3 Se Lei ha acquistato in qualità di Professionista gli articoli precedenti non trovano applicazione.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        <strong>Art. 7 Composizione stragiudiziale delle controversie - Alternative Dispute Resolution/Online Dispute Resolution</strong>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        7.1 Il Venditore informa l'utente che rivesta la qualifica di Consumatore che, nel caso in cui egli abbia presentato un reclamo direttamente al Venditore, a seguito del quale non sia stato tuttavia possibile risolvere la controversia così insorta, il Venditore fornirà le informazioni in merito all'organismo o agli organismi di Alternative Dispute Resolution per la risoluzione extragiudiziale delle controversie relative ad obbligazioni derivanti da un contratto concluso in base alle presenti Condizioni Generali di Vendita (organismi ADR), precisando se intenda avvalersi o meno di tali organismi per risolvere la controversia stessa.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        7.2 Il Venditore informa inoltre l'utente che rivesta la qualifica di Consumatore che è stata istituita una piattaforma europea per la risoluzione on-line delle controversie dei consumatori (c.d. piattaforma ODR). La piattaforma ODR è consultabile al seguente indirizzo&#xa0;<a href="http://ec.europa.eu/consumers/odr" target="_blank" style="text-decoration:none"><span class="Hyperlink">http://ec.europa.eu/consumers/odr</span></a>. Attraverso la piattaforma ODR l'utente Consumatore potrà consultare l'elenco degli organismi ADR, trovare il link al sito di ciascuno di essi e avviare una procedura di risoluzione on-line della controversia in cui sia coinvolto.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        7.3 Sono fatti salvi in ogni caso il diritto dell'utente Consumatore di adire il giudice ordinario competente della controversia derivante dalle presenti Condizioni Generali di Vendita, qualunque sia l'esito della procedura di composizione extragiudiziale delle controversie relative ai rapporti di consumo mediante ricorso alle procedure di cui alla Parte V, Titolo II-bis Codice del Consumo.
                    </p>
                    <p>
                        L’utente che risiede in uno stato membro dell’Unione Europea diverso dall’Italia, può, inoltre, accedere, per ogni controversia relativa all’applicazione, esecuzione e interpretazione delle presenti Condizioni Generali di Vendita, al procedimento europeo istituito per le controversie di modesta entità, dal Regolamento (CE) n. 861/2007 del Consiglio, dell’11 luglio 2007, a condizione che il valore della controversia non ecceda, esclusi gli interessi, i diritti e le spese, Euro 5.000,00. Il testo del regolamento è reperibile sul sito&#xa0;<a href="http://www.eur-lex.europa.eu/" target="_blank" style="text-decoration:none"><span class="Hyperlink">http://www.eur-lex.europa.eu</span></a>.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        <strong>Art. 8 Servizio clienti</strong>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        8.1&#xa0;È possibile chiedere informazioni, inviare comunicazioni, richiedere assistenza o inoltrare reclami contattando il&#xa0;Venditore ai recapiti indicati in Premessa, oppure utilizzando il form di contatto eventualmente presente sul Sito.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        8.2 Il Venditore risponde in un tempo indicativo pari a 7 giorni.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        <strong>Art. 9 Materiale pubblicato sul Sito. Recensioni</strong>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        9.1 Qualsiasi materiale pubblicato sul Sito è protetto dalle leggi sul diritto d'autore applicabili. Senza autorizzazione del Venditore o del terzo titolare del diritto d’autore, è fatto divieto di copiare, pubblicare, modificare o utilizzare in qualsiasi modo e per qualsiasi finalità qualsiasi materiale pubblicato sul Sito.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        9.2 Ai sensi di quanto previsto dal Decreto Legislativo No. 26 del 7 marzo 2023, il Sito La informa che sul Sito non è implementato alcun tool che permette agli utenti di pubblicare recensioni.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        9.3 Lei è comunque invitato ad accedere al presente articolo in occasione di futuri acquisti per verificare se sul Sito è stato un implementato un tool che permette agli utenti di pubblicare le proprie recensioni riferite ad esperienze di acquisto sul Sito.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        <strong>Art. 10 Legge applicabile. Foro applicabile</strong>
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        10.1 Si ricorda che nel caso di utente Consumatore, per ogni controversia relativa all’applicazione, esecuzione e interpretazione del presente documento è competente il foro del luogo in cui l’utente risiede o ha eletto domicilio.&#xa0;Nel caso di utente professionista, per ogni controversia relativa all’applicazione, esecuzione e interpretazione del presente documento è invece competente il Foro ove ha sede il Venditore ai sensi di quanto previsto in Premessa.
                    </p>
                    <p>
                        &#xa0;
                    </p>
                    <p>
                        &#xa0;
                    </p>
            <p style="bottom: 10px; right: 10px; position: absolute;"><a href="https://wordtohtml.net" target="_blank" style="font-size:11px; color: #d0d0d0">Convertito in HTML con WordToHTML.net</a></p>
        <script data-cfasync="false" src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script defer src="https://static.cloudflareinsights.com/beacon.min.js/vcd15cbe7772f49c399c6a5babf22c1241717689176015" integrity="sha512-ZpsOmlRQV6y907TI0dKBHq9Md29nnaEIPlkf84rnaERnq6zvWvPUqr2ft8M1aS28oN72PdrCzSjY4U6VaAw1EQ==" data-cf-beacon='{"rayId":"8e399e606a9e9769","version":"2024.10.5","r":1,"token":"4581d6a58cf94e929fad52deda295622","serverTiming":{"name":{"cfExtPri":true,"cfL4":true,"cfSpeedBrain":true,"cfCacheStatus":true}}}' crossorigin="anonymous"></script>
        </body>
        </html>`


    return (
        <div className='m-5'>
            <h1 className='white'>Terms and Conditions</h1>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
    );
};

export default TermsAndConditions;